<template>
    <div>
        <b-modal ref="refModalMaintenance" hide-footer title="Solicitar Mantenimiento" 
        no-close-on-esc no-close-on-backdrop
		size="sm"
        :visible="isActive"
        @hidden="handleClose" >
			<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
				<b-row>
					<b-col cols="12">
						<b-form-group label="Unidad" :description="statusTruckActuall == 'En mantenimiento' ? 'Actualmente esta en mantenimiento' : ''">
							<v-select id="truck_id" v-model="form.truck_id" :options="trucks"
                                label="label" :reduce="option => option.id" :clearable="false"
                                class="select-size-sm"
                                placeholder="Selecciona una unidad"
								@input="selectTruck">
								<template #option="{ disabled, estatus, label }">
									<div :class="{ 'text-red-700': disabled }">
										{{ label }}
										<span v-if="estatus === 'En mantenimiento'" class="text-xs text-bold text-gray-500">
											<br>En mantenimiento
										</span>
									</div>
								</template>
							</v-select>
						</b-form-group>
					</b-col>

					<b-col cols="12" md="12" sm="12">
						<b-form-group label="Kilometraje" description="Último Kilometraje registrado">
							<b-form-input size="sm" id="odometer" v-model="form.odometer" type="number" placeholder="Kilometraje" />
						</b-form-group>
					</b-col>

					<b-col cols="12" md="12" sm="12">
						<b-form-group label="Tipo">
							<b-form-radio-group v-model="form.type">
								<b-form-radio value="Preventivo">Preventivo</b-form-radio>
								<b-form-radio value="Correctivo">Correctivo</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>

					<b-col cols="12" md="12">
						<b-form-group label="Prioridad">
							<b-form-radio-group v-model="form.tag">
								<b-form-radio v-for="tag in tags" :key="tag.id" :value="tag.id" :class="['custom-control-' + tag.color]">
								{{ tag.name }}
								</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>

					<!-- Descripcion del mantenimiento -->
					<b-col cols="12">
						<b-form-group label="Descripción">
							<b-form-textarea id="description" v-model="form.description" rows="3"
								placeholder="Breve descripcion sobre el mantenimiento" />
						</b-form-group>
					</b-col>
				</b-row>

				<div class="text-right">
					<b-button size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="m-1" variant="outline-secondary" @click="handleClose">
						Cerrar
					</b-button>
					<b-button @click="addMaintenance" :disabled="loading" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
						Guardar
					</b-button>
				</div>
			</b-overlay>
		</b-modal>
    </div>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import {
	BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
	BTabs, BTab, BModal, BForm, BFormGroup, BFormInput, BFormTextarea, BCard, BCardBody, BCardHeader, BRow, BCol,
	BOverlay, BFormRadioGroup, BFormSelect, BFormRadio,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { computed, nextTick, onMounted, ref, watch } from '@vue/composition-api'
import * as helper from '@/libs/helpers';
import Swal from "sweetalert2";
import "animate.css";
import moment from 'moment'
import vSelect from "vue-select";

import WebCamionesProvider from '@/providers/WebCamiones';
const WebCamionesResource = new WebCamionesProvider();

export default {
    name: "ModalAddMaintenance",
    components: {
        BNavItemDropdown,
		BBadge,
		BMedia,
		BLink,
		BAvatar,
		VuePerfectScrollbar,
		BButton,
		BFormCheckbox,
		BTabs,
		BTab,
		BModal,
		BFormInput,
		BForm,
		BFormGroup,
		BFormTextarea,
		BCard,
		BCardBody,
		BCardHeader,
		BRow,
		BCol,
		BOverlay,
		BFormRadioGroup,
		BFormSelect,
		BFormRadio,
		
		//
		vSelect,
    },
    directives: {
		Ripple,
	},
    props:{
        active: {
            type: Boolean,
            required: true
        },
		truck_id: {
			type: Number,
			required: false
		},
        trucks: {
            type: Array,
            required: true
        },
    },
    setup(props, { emit }) {

        const form = ref({
			user_id: 1, // ID del usuario
			truck_id: null,
			description: null,
			identifier: null,
			status: 'Pendiente',
			type: 'Preventivo',
			company_id: store.getters['auth/getConexionId'],
			user_name: store.getters['auth/getUser'].userName,
			tag: 'Media',
			odometer: null,
		})
        const statusTruckActuall = ref('')
        const loading = ref(false)
        const isActive = ref(false)

        const tags = ref([
			{ id: 'Baja', name: 'Baja', color: 'info' },
			{ id: 'Media', name: 'Media', color: 'warning' },
			{ id: 'Alta', name: 'Alta', color: 'danger' },
		])

        const handleClose = () => {
            isActive.value = false
            nextTick(() => {
                form.value = {
                    user_id: 1, // ID del usuario
                    truck_id: null,
                    description: null,
                    identifier: null,
                    status: 'Pendiente',
                    type: 'Preventivo',
                    company_id: store.getters['auth/getConexionId'],
                    user_name: store.getters['auth/getUser'].userName,
                    tag: 'Media',
                    odometer: null,
                }
            })
        }

        const selectTruck = (value) => {
            const truck = props.trucks.find(item => item.id == value)
			if (truck) {
				form.value.odometer = truck.odometro
				statusTruckActuall.value = truck.estatus
			}
        }

        const addMaintenance = async () => {
            loading.value = true
			const truck = props.trucks.find(item => item.id == form.value.truck_id)
			form.value.identifier = truck.label
			try {
				const { data } = await axios.post(`${process.env.VUE_APP_API_PRICES}/api/client/maintenances`, form.value, {
					headers: {
						Authorization: `Bearer ${store.getters['auth/getTokenCloud'].access_token}`,
						Accept: 'application/json',
					},
				})				

				if (data.success) {
				
                    //Actualizar el estatus del camión a En mantenimiento
                    // truck.estatus = 'En mantenimiento'
                    // truck.odometro = form.value.odometer
                    // await WebCamionesResource.update(truck)
					helper.success(data.message)
					handleClose()
                    emit('add-maintenance', data.data)
				} else {
					helper.danger(data.message)
				}

			}catch(e) {
				helper.handleResponseErrors(e)
			} finally {
				loading.value = false
			}
        }

        watch(() => props.active, (value) => {
            isActive.value = value
        })

		watch(() => props.truck_id, (value) => {
			if (value) {
				form.value.truck_id = value
				selectTruck(value)
			}
		})

        watch(isActive, (value) => {
            if (value !== props.active) {
                emit('update:active', value)
            }
        })

        return {
            // data
            form,
            isActive,
            statusTruckActuall,
            loading,
            tags,

            // methods
            handleClose,
            selectTruck,
            addMaintenance,
        }
    },
}
</script>